import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
      <path
        d="M21 12.0004C21 12.1993 20.921 12.3901 20.7803 12.5307C20.6397 12.6714 20.4489 12.7504 20.25 12.7504H5.56029L11.0306 18.2198C11.1003 18.2895 11.1556 18.3722 11.1933 18.4632C11.231 18.5543 11.2504 18.6519 11.2504 18.7504C11.2504 18.849 11.231 18.9465 11.1933 19.0376C11.1556 19.1286 11.1003 19.2114 11.0306 19.281C10.9609 19.3507 10.8782 19.406 10.7872 19.4437C10.6961 19.4814 10.5985 19.5008 10.5 19.5008C10.4014 19.5008 10.3039 19.4814 10.2128 19.4437C10.1218 19.406 10.039 19.3507 9.96935 19.281L3.21935 12.531C3.14962 12.4614 3.0943 12.3787 3.05656 12.2876C3.01882 12.1966 2.99939 12.099 2.99939 12.0004C2.99939 11.9019 3.01882 11.8043 3.05656 11.7132C3.0943 11.6222 3.14962 11.5394 3.21935 11.4698L9.96935 4.71979C10.1101 4.57906 10.301 4.5 10.5 4.5C10.699 4.5 10.8899 4.57906 11.0306 4.71979C11.1713 4.86052 11.2504 5.05139 11.2504 5.25042C11.2504 5.44944 11.1713 5.64031 11.0306 5.78104L5.56029 11.2504H20.25C20.4489 11.2504 20.6397 11.3294 20.7803 11.4701C20.921 11.6107 21 11.8015 21 12.0004Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon
