import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 8.25C11.2583 8.25 10.5333 8.46993 9.91661 8.88199C9.29993 9.29404 8.81928 9.87971 8.53545 10.5649C8.25162 11.2502 8.17736 12.0042 8.32205 12.7316C8.46675 13.459 8.8239 14.1272 9.34835 14.6517C9.8728 15.1761 10.541 15.5333 11.2684 15.6779C11.9958 15.8226 12.7498 15.7484 13.4351 15.4645C14.1203 15.1807 14.706 14.7001 15.118 14.0834C15.5301 13.4667 15.75 12.7417 15.75 12C15.75 11.0054 15.3549 10.0516 14.6517 9.34835C13.9484 8.64509 12.9946 8.25 12 8.25ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM22.5 5.25H1.5C1.30109 5.25 1.11032 5.32902 0.96967 5.46967C0.829018 5.61032 0.75 5.80109 0.75 6V18C0.75 18.1989 0.829018 18.3897 0.96967 18.5303C1.11032 18.671 1.30109 18.75 1.5 18.75H22.5C22.6989 18.75 22.8897 18.671 23.0303 18.5303C23.171 18.3897 23.25 18.1989 23.25 18V6C23.25 5.80109 23.171 5.61032 23.0303 5.46967C22.8897 5.32902 22.6989 5.25 22.5 5.25ZM18.1547 17.25H5.84531C5.5935 16.3984 5.13263 15.6233 4.50467 14.9953C3.87671 14.3674 3.10162 13.9065 2.25 13.6547V10.3453C3.10162 10.0935 3.87671 9.63263 4.50467 9.00467C5.13263 8.37671 5.5935 7.60162 5.84531 6.75H18.1547C18.4065 7.60162 18.8674 8.37671 19.4953 9.00467C20.1233 9.63263 20.8984 10.0935 21.75 10.3453V13.6547C20.8984 13.9065 20.1233 14.3674 19.4953 14.9953C18.8674 15.6233 18.4065 16.3984 18.1547 17.25ZM21.75 8.75344C20.8504 8.36662 20.1334 7.64959 19.7466 6.75H21.75V8.75344ZM4.25344 6.75C3.86662 7.64959 3.14959 8.36662 2.25 8.75344V6.75H4.25344ZM2.25 15.2466C3.14959 15.6334 3.86662 16.3504 4.25344 17.25H2.25V15.2466ZM19.7466 17.25C20.1334 16.3504 20.8504 15.6334 21.75 15.2466V17.25H19.7466Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon
