import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 5 4" fill="none">
      <circle cx="2.5" cy="2" r="2" fill="currentColor" />
    </svg>
  )
}

export default Icon
