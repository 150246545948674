import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 25 24" fill="none">
      <path
        d="M12.6666 2.25C10.7383 2.25 8.8532 2.82183 7.24982 3.89317C5.64644 4.96451 4.39676 6.48726 3.6588 8.26884C2.92085 10.0504 2.72777 12.0108 3.10397 13.9021C3.48018 15.7934 4.40878 17.5307 5.77234 18.8943C7.1359 20.2579 8.87319 21.1865 10.7645 21.5627C12.6558 21.9389 14.6162 21.7458 16.3978 21.0078C18.1794 20.2699 19.7021 19.0202 20.7735 17.4168C21.8448 15.8134 22.4166 13.9284 22.4166 12C22.4137 9.41505 21.3855 6.93683 19.5576 5.109C17.7298 3.28116 15.2516 2.25298 12.6666 2.25ZM20.881 11.25H17.1469C16.9791 7.82719 15.661 5.40656 14.5801 3.975C16.2585 4.37804 17.7686 5.29628 18.8988 6.60095C20.0289 7.90562 20.7224 9.53129 20.8819 11.25H20.881ZM9.68819 12.75H15.6451C15.4285 16.6509 13.561 19.0416 12.6666 19.9688C11.7713 19.0406 9.90476 16.65 9.68819 12.75ZM9.68819 11.25C9.90476 7.34906 11.7723 4.95844 12.6666 4.03125C13.5619 4.96219 15.4285 7.35281 15.6451 11.25H9.68819ZM10.7532 3.975C9.67226 5.40656 8.35413 7.82719 8.18632 11.25H4.45132C4.61088 9.53129 5.30435 7.90562 6.43451 6.60095C7.56466 5.29628 9.07481 4.37804 10.7532 3.975ZM4.45132 12.75H8.18632C8.35694 16.1728 9.67226 18.5934 10.7532 20.025C9.07481 19.622 7.56466 18.7037 6.43451 17.399C5.30435 16.0944 4.61088 14.4687 4.45132 12.75ZM14.5763 20.025C15.6573 18.5934 16.9726 16.1728 17.1432 12.75H20.8782C20.7189 14.4682 20.0259 16.0935 18.8965 17.3981C17.7671 18.7027 16.2578 19.6213 14.5801 20.025H14.5763Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon
