import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
      <path
        d="M21.5299 20.4694L16.8358 15.7763C18.1963 14.1429 18.8748 12.0478 18.73 9.92694C18.5852 7.80607 17.6283 5.82268 16.0584 4.38935C14.4885 2.95602 12.4264 2.18311 10.3012 2.23141C8.1759 2.27971 6.15108 3.1455 4.64791 4.64867C3.14474 6.15184 2.27895 8.17666 2.23065 10.3019C2.18235 12.4272 2.95526 14.4892 4.38859 16.0591C5.82191 17.629 7.80531 18.5859 9.92618 18.7307C12.047 18.8755 14.1421 18.1971 15.7755 16.8366L20.4686 21.5306C20.5383 21.6003 20.621 21.6556 20.7121 21.6933C20.8031 21.731 20.9007 21.7504 20.9992 21.7504C21.0978 21.7504 21.1954 21.731 21.2864 21.6933C21.3775 21.6556 21.4602 21.6003 21.5299 21.5306C21.5995 21.4609 21.6548 21.3782 21.6925 21.2872C21.7302 21.1961 21.7497 21.0985 21.7497 21C21.7497 20.9015 21.7302 20.8039 21.6925 20.7128C21.6548 20.6218 21.5995 20.5391 21.5299 20.4694ZM3.74924 10.5C3.74924 9.16498 4.14512 7.85993 4.88682 6.7499C5.62852 5.63987 6.68272 4.77471 7.91612 4.26381C9.14953 3.75292 10.5067 3.61925 11.8161 3.8797C13.1255 4.14015 14.3282 4.78303 15.2722 5.72703C16.2162 6.67103 16.8591 7.87377 17.1195 9.18314C17.38 10.4925 17.2463 11.8497 16.7354 13.0831C16.2245 14.3165 15.3594 15.3707 14.2493 16.1124C13.1393 16.8541 11.8343 17.25 10.4992 17.25C8.70964 17.248 6.9939 16.5362 5.72846 15.2708C4.46302 14.0053 3.75122 12.2896 3.74924 10.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon
