import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 25 24" fill="none">
      <path
        d="M20.6973 9.53055L13.1973 17.0306C13.1276 17.1003 13.0449 17.1556 12.9539 17.1933C12.8628 17.2311 12.7652 17.2505 12.6667 17.2505C12.5681 17.2505 12.4705 17.2311 12.3795 17.1933C12.2884 17.1556 12.2057 17.1003 12.1361 17.0306L4.63605 9.53055C4.49532 9.38982 4.41626 9.19895 4.41626 8.99993C4.41626 8.80091 4.49532 8.61003 4.63605 8.4693C4.77678 8.32857 4.96765 8.24951 5.16668 8.24951C5.3657 8.24951 5.55657 8.32857 5.6973 8.4693L12.6667 15.4396L19.6361 8.4693C19.7057 8.39962 19.7885 8.34435 19.8795 8.30663C19.9705 8.26892 20.0681 8.24951 20.1667 8.24951C20.2652 8.24951 20.3628 8.26892 20.4538 8.30663C20.5449 8.34435 20.6276 8.39962 20.6973 8.4693C20.767 8.53899 20.8223 8.62171 20.86 8.71276C20.8977 8.8038 20.9171 8.90138 20.9171 8.99993C20.9171 9.09847 20.8977 9.19606 20.86 9.2871C20.8223 9.37815 20.767 9.46087 20.6973 9.53055Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon
